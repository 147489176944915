<template>
  <div class="container_" v-if="isImg">
    <el-upload
      ref="upload"
      :action="uploadUrl"
      :before-upload="(file) => beforeUpload(file, sizeLimit, sizeUnit)"
      :class="files.length === limit ? 'upload-hidden' : ''"
      :disabled="disabled"
      :headers="headers"
      :file-list="fileList"
      name="attachFiles"
      :limit="limit"
      :multiple="multiple"
      :on-preview="onPreView"
      :on-remove="fileRemove"
      :on-success="uploadSuccess"
      list-type="picture-card"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <div class="tip" v-if="tip">{{ tip }}</div>
    <el-dialog :modal="false" :visible.sync="dialogVisible" title="图片">
      <img :src="imageUrl" alt width="100%" />
    </el-dialog>
  </div>
  <div class="container_" v-else>
    <el-upload
      ref="upload"
      :action="uploadUrl"
      :before-upload="(file) => beforeUpload(file, sizeLimit, sizeUnit)"
      :headers="headers"
      :disabled="disabled"
      :file-list="fileList"
      name="attachFiles"
      :limit="limit"
      :multiple="multiple"
      :on-preview="onPreView"
      :on-remove="fileRemove"
      :on-success="uploadSuccess"
      :on-exceed="exceed"
    >
      <el-button type="primary" >点击上传</el-button>
      <div class="tip" slot="tip">{{ tip || '请上传' }}</div>
    </el-upload>
    <!-- <label> 附件说明: </label>
    <el-input type="text" v-model="remark" placeholder="请输入" :maxlength="100"></el-input> -->
  </div>
</template>

<script>
import config from '@/http/config.js';
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import { getToken } from '@/http/auth';

export default {
  props: {
    files: {
      type: Array,
      require: true,
    },
    // 限制文件格式
    fileType: {
      type: Array,
      default: null,
    },
    // 限制文件数量
    limit: {
      type: Number,
    },
    tip: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    isImg: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sizeLimit: {
      type: Number,
      default: 20,
    },
    sizeUnit: {
      type: String,
      default: 'M',
    },
  },
  data() {
    return {
      uploadUrl: `${config.baseURL}/fileUpload/upload`,
      headers: { Authorization: getToken() || '' },
      dialogVisible: false,
      imageUrl: '',
      // remark: '',
      uploadEndNumber: true,
      fileList: [],
    };
  },
  methods: {
    exceed() {
      this.$message.error({ message: '最多上传' + this.$props.limit + '个文件', offset: 80 });
    },
    beforeUpload(file, size, unit) {
      this.uploadEndNumber = this.$props.files.length;
      const fileSize = file.size;
      const sizeLimit = unit === 'M' ? size * 1024 * 1024 : size * 1024;
      if (fileSize > sizeLimit) {
        this.$message.error({ message: '上传文件不能超过' + size + unit, offset: 80 });
        return false;
      }

      if (this.$props.fileType.length === 0 || this.$props.fileType.indexOf('') > -1) {
        return true;
      } else {
        const suffix = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
        if (this.$props.fileType.indexOf(suffix) > -1) {
          return true;
        } else {
          this.$message.error({ message: `只支持上传${this.$props.fileType.join()}格式文件`, offset: 80 });
          return false;
        }
      }

      // const suffixName = file.name.replace(/.+\./, '')
      // if (!this.fileLimit.includes(suffixName)) {
      //   const message = this.fileLimit.join('/')
      //   this.$message.error(`只支持${message}格式`)
      // }
      // return this.fileLimit.includes(suffixName)
    },
    uploadSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      this.uploadEndNumber++;
      if (this.uploadEndNumber == fileList.length) {
        // 是否上传完成最后一张
        this.uploadEndNumber = true;
      }
      if (res.code === 2000) {
        this.$props.files.push({
          fileName: res.result.fileName.split('.')[0],
          attachmentUrl: res.result.realPaths.split(',')[0],
        });
      } else {
        this.fileList = this.file.map((item) => {
          return item;
        });
      }
    },
    fileRemove(file) {
      if (file && file.url) {
        this.$props.files.splice(
          this.$props.files.findIndex((v) => {
            return v.attachmentUrl == file.url.replace(this.fileUrl, '');
          }),
          1
        );
      }
    },

    onPreView(file) {
      if (this.$props.isImg) {
        //预览图片
        this.dialogVisible = true;
        this.imageUrl = file.url;
      } else {
        //下载文件
        saveAs(`${file.url}`, `${file.name}`);
      }
    },
  },
  watch: {
    files() {
      // 上传完最后一张 再赋值
      if (this.uploadEndNumber === true) {
        this.fileList = this.file.map((item) => {
          return item;
        });
      }
    },
  },
  computed: {
    ...mapState({
      fileUrl: (state) => state.fileUrl,
    }),
    // fileLimit() {
    //   let fileType = [];
    //   if (typeof this.$props.fileType === 'string') {
    //     fileType.push(this.$props.fileType);
    //   } else {
    //     fileType = fileType.concat(this.$props.fileType);
    //   }
    //   return fileType;
    // },
    file() {
      return this.$props.files.map((item) => {
        const url = item.attachmentUrl.indexOf('http') > -1 ? item.attachmentUrl : this.fileUrl + item.attachmentUrl;
        return {
          name: item.fileName,
          url: url,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container_ {
  position: relative;
  ::v-deep .tip {
    font-size: 12px;
    color: #666666;
    margin-top: 10px;
    line-height: 20px;
  }
  .upload-hidden {
    ::v-deep .el-upload {
      display: none;
    }
  }
  label {
    position: absolute;
    bottom: 0;
    left: -70px;
    font-size: 14px;
  }
}
</style>
